import type { LiHTMLAttributes } from "react";
import styled, { css } from "styled-components";

const CheckList = styled.ul`
  margin-top: 0;
  padding: 0;
  list-style-type: none;
`;

export default CheckList;

export const BACKGROUND_POSITION_Y = "6px";

export const checkStyle = css`
  ${(props) => {
    const fill = encodeURIComponent(props.theme.nafColor.primary.park);
    const stroke = encodeURIComponent("white");
    return css`
      background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-6 -6 152 152' width='16' height='16'><circle fill='${fill}' r='76' cx='70' cy='70'/><path fill='${stroke}' d='M114.587 47.48l-62.93 58.67-.44-.47-12.37-13.27-18.281-19.6 13.54-12.62 18.281 19.6 49.389-46.05z'/></svg>")
        no-repeat left ${BACKGROUND_POSITION_Y};
    `;
  }}
`;

export const negativeStyle = css`
  ${(props) => {
    const fill = encodeURIComponent(props.theme.nafColor.neutral.neutral3);
    return css`
      background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 140 140' width='16' height='16'><circle fill='${fill}' r='70' cx='70' cy='70'/><rect fill='white' x='30' y='60' width='80' height='20' /></svg>")
        no-repeat left ${BACKGROUND_POSITION_Y};
    `;
  }}
`;

interface CheckListItemProps extends LiHTMLAttributes<HTMLLIElement> {
  checked: boolean;
}
export const CheckListItem = styled(function ({
  checked: _,
  ...props
}: CheckListItemProps) {
  return <li {...props} />;
})`
  padding: ${({ theme }) =>
    `0 0 ${theme.spacing.space8} ${theme.spacing.space24}`};

  ${(props) => (props.checked ? checkStyle : negativeStyle)}
`;
